@import "./base.css";

.about-us,
.leadership {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --text-color: #333333;
  color: var(--text-color);
}

.ania-content .page-title {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 2rem;
  color: #007cba;
}

.about-us-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 804px;
}

.about-us h2 {
  font-weight: 400;
}
.about-us h2 {
  font-size: 20px;
  margin: 1.5rem 0;
}
.about-us-img {
  height: auto;
}
.about-us-img img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}
.stats {
  text-align: center;
  margin: 2.5rem 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) 2;
  grid-template-columns: repeat(2, 1fr);
}
.stats strong {
  display: block;
  font-size: 34px;
  font-weight: 400;
  margin-bottom: 12px;
}
.stats small {
  font-size: 16px;
}
.stats > div:nth-child(1) {
  border-right: 1px solid;
}

.about-us p {
  line-height: 1.5;
}

.about-us-content {
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.desktop-img {
  display: block;
}

.about-us-content ul{
  line-height: 25px;
  margin-left: 25px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .about-us-content {
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 740px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1100px) {
  .about-us-content {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 700px;
  }
}

@media (min-width: 576px) {
  .stats {
    -ms-grid-columns: (auto) 3;
    grid-template-columns: repeat(3, auto);
  }
  .stats > div {
    padding: 0 0.5rem;
  }
  .stats > div:last-child {
    border-left: 1px solid;
  }
  .user {
    -ms-grid-columns: 176px 1fr;
    grid-template-columns: 176px 1fr;
    grid-template-areas: "pic details" "pic description" "pic .";
    -ms-flex-line-pack: start;
    align-content: start;
  }
  .user .user-pic {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-row: 1;
    grid-column: 1;
    grid-area: pic;
    width: 176px;
    height: 190px;
  }
  .user-description {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    grid-row: 2;
    grid-column: 2;
    -ms-grid-column-span: 1;
  }
  .user-description {
    margin-top: 0;
  }
}

@media (max-width: 575.98px) {
  .user-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (min-width: 576px) {
  .stats > div {
    padding: 0 1.5rem;
  }
}

@media (min-width: 768px) {
  .stats > div {
    padding: 0 2.5rem;
  }
}
@media screen and (min-width: 990px) {
  .about-us h2 {
    font-size: 34px;
    margin-bottom: 2.5rem;
  }
  .about-us p {
    font-size: 16px;
    margin-bottom: 1.5rem;
  }
  /* .about-us-img {
        height: 490px;
      } */

  .about-us-content {
    padding-top: 3rem;
    padding-bottom: 5rem;
  }
}

@media screen and (min-width: 1230px) {
  .about-us-img {
    padding: 0 32px;
  }
}

@media screen and (max-width: 401px) {
  .desktop-img {
    display: none;
  }
}
