@import "./base.css";
.contact-us .office-hours .title {
  margin-bottom: 22px;
}

.drop-1 {
  width: 100%;
  font-family: "Montserrat-Regular";
  height: calc(2.5em + 0.75rem + 10px);
  background: #ffffff;
  border: 1px solid #9d9d9d;
  box-sizing: border-box;
  padding: 0px 20px;
}
.ania-form .MuiSelect-select {
  padding-left: 22px !important;
}
.row .col-lg-6 .MuiSelect-select {
  padding-left: 0 !important;
}
.ania-form .mb-3:nth-child(4) .MuiFormControl-root {
  padding: 0px;
}
#menu-typeOfInquiry {
  margin-top: 12px;
}

.contact-us .rental-form-title {
  margin-top: 42px;
  margin-bottom: 34px;
  font-family: "Montserrat-Regular";
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
}
.contact-us .ania-content {
  padding: 2rem 0;
}
.ania-content.inquiry {
  padding-bottom: 6.25rem;
}
.ania-content .page-title {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 2rem;
  color: #007cba;
}
select#exampleFormControlSelect1 {
  word-break: break-all;
  white-space: break-spaces;
}

.contact-us .form-title {
  font-size: 26px;
  font-family: var(--regular-font);
  margin-top: 39px;
  color: var(--color-black);
  max-width: 630px;
  line-height: 37px;
}

.ania-form .form-control:not(textarea) {
  height: calc(2.5em + 0.75rem + 8px);
}

.ania-form textarea.form-control {
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 310px;
}

.ania-form .form-control {
  border: 2px solid #ced4da;
  border-radius: 0;
  resize: none;
  padding: 0.375rem 1.5rem;
  font-size: 1.125rem;
  color: var(--color-black);
}

.contact-btn {
  width: 327px;
  height: 67px;
  /* margin-bottom: 80px; */
}

.ania-content .breadcrumb {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem 0.75rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: var(--color-white);
  border-radius: 0.25rem;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.contact-us .head-office p,
.contact-us .office-hours p {
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 1.371rem;
}

.contact-us .head-office .title,
.contact-us .office-hours .title {
  color: var(--color-black);
  font-family: var(--bold-font);
  text-transform: uppercase;
  font-size: 16px;
}

.contact-us .ania-content .main-content {
  padding-top: 0px;
  padding-bottom: 0px;
}

.contact-us .head-office .company-name {
  font-size: 34px;
  font-family: var(--regular-font);
  color: var(--color-basic);
  text-transform: uppercase;
  padding: 5% 0;
}

.contact-us .office-hours .office-day {
  text-transform: capitalize;
}
.contact-us .office-hours .office-time {
  margin-bottom: 20px;
}

.contact-us .embed-responsive {
  height: 50vh;
}

.align-left {
  left: 45px;
}

.dekstop-hide {
  display: none;
}

.hide-mobile {
  display: block;
}

.hide-mobile-1 {
  display: block;
}

.dekstop-hide-1 {
  display: none;
}

@media screen and (max-width: 992px) {
  .contact-btn {
    width: 100%;
    height: 67px;
    margin-bottom: 0;
  }

  .align-left {
    left: 0px;
  }
  .contact-us .ania-content .page-title {
    text-align: left;
    font-size: 30px;
  }
  .contact-us .main-content .border-top {
    border: 0px !important;
  }
  .contact-us .ania-content .main-content {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .hide-mobile {
    display: none;
  }
  .dekstop-hide {
    display: block;
  }
  .contact-us .rental-form-title {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.1em;
  }
}
@media (max-width: 1230px) {
  .hide-mobile-1 {
    display: none;
  }
  .dekstop-hide-1 {
    display: block;
  }
}

@media (max-width: 500px) {
  .hide-mobile-1 {
    display: none;
  }
  .dekstop-hide-1 {
    display: block;
  }
  .selectItemField {
    font-size: 0.8rem !important;
  }
  .contact-us .MuiOutlinedInput-root .MuiOutlinedInput-input {
    font-size: 0.8rem !important;
  }
}
