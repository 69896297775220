@import "./base.css";
.contact-us .head-office p.office-phone {
  margin-bottom: 60px;
}
.contact-us .ania-content {
  padding: 2rem 0;
}
.ania-content.inquiry {
  padding-bottom: 6.25rem;
}
.ania-content .page-title {
  font-size: 40px;
  font-family: var(--regular-font);
  line-height: 51px;
  text-align: center;
  /* margin-bottom: 2rem; */
  color: #007cba;
}
/* .MuiPaper-root {
  box-shadow: none !important;
  border-radius: 0px !important;
  overflow: hidden !important;
  
} */
#menu-bedroomCount .MuiPaper-root {
  width: 380px !important;
}
.full-width {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
}
.form-control-options-rental-page {
  color: grey;
}
.rental-form-control-options {
  margin-left: 10px;
}

.breadcrumb-width {
  padding-right: 15px;
  padding-left: 32px;
  margin-right: auto;
  margin-left: auto;
  /* max-width: 1440px; */
}

.contact-us .form-title {
  font-size: 26px;
  font-family: var(--regular-font);
  margin-top: 39px;
  color: var(--color-black);
  max-width: 630px;
  line-height: 37px;
}

.ania-form .form-control:not(textarea) {
  height: calc(2.5em + 0.75rem + 8px);
}
.ania-form textarea.form-control {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.ania-form .form-control {
  border: 2px solid #ced4da;
  border-radius: 0;
  resize: none;
  padding: 0.375rem 1.5rem;
  font-size: 1.125rem;
  color: var(--color-black);
}

.ania-content .breadcrumb {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem 0.75rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: var(--color-white);
  border-radius: 0.25rem;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.contact-us .head-office p,
.contact-us .office-hours p {
  margin-bottom: 3px;
  font-size: 20px;
  line-height: 27px;
}

.contact-us .head-office .title,
.contact-us .office-hours .title {
  color: var(--color-black);
  font-family: var(--bold-font);
  text-transform: uppercase;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2em;
}
.contact-us .ania-content .main-content {
  padding-top: 0px;
  padding-bottom: 0px;
}

.contact-us .head-office .company-name {
  font-size: 34px;
  font-family: var(--regular-font);
  color: var(--color-basic);
  text-transform: uppercase;
  line-height: 44px;
  /* padding: 22px 0; */
}

.contact-us .office-hours .office-day {
  text-transform: capitalize;
}
.contact-us .office-hours .office-time {
  margin-bottom: 20px;
}

.contact-us .embed-responsive {
  height: 50vh;
}

.inquiry-btn {
  width: 327px;
  height: 67px;
}

@media screen and (max-width: 992px) {
  .inquiry-btn {
    width: 100%;
    height: 67px;
  }

  .contact-us .ania-content .page-title {
    text-align: left;
    font-size: 30px;
  }
  .contact-us .main-content .border-top {
    border: 0px !important;
  }
  .contact-us .ania-content .main-content {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ania-content.inquiry {
    padding-bottom: 0rem;
  }
  .inquiry p.office-address {
    font-size: 16px;
  }

  .ania-content .page-title {
    font-size: 30px;
    line-height: 39px;
  }

  .contact-us .head-office p,
  .contact-us .office-hours p {
    margin-bottom: 3px;
    font-size: 16px;
    line-height: 22px;
  }
}
