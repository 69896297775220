
  .full-width.nav-max {
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .navbar-main{
    position: sticky;
    top: 0;
    z-index: 9;
  }
  .mobile-nav{
    position: absolute;
    top: 60px;
    z-index: 2;
    background: white;
    width: 100%;
  }
  
  /* / NAVBAR / */
  .nav-ul {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  
  .nav-ul img {
    margin-left: 5px;
  }
  .navbar-nav {
    margin: 0px;
  }
  .navbar{
    padding-top: 23px;
    padding-bottom: 20px;
  }
   
  .nav-color{
    color: var(--color-theme) !important;
  }

  .navbar-brand {
    padding: 0;
  }
  
  .navbar-brand img {
    width: 320px;
    height: auto;
  }
  .navbar-expand-custom .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 2.5rem;
    font-size: 16px;
    text-align: right;
    color: var(--color-black); 
    font-family: var(--regular-font);
    line-height: 22px;
  }
  
  
  .navbar-expand-custom .navbar-nav .nav-link:hover {
    color: var(--color-gray);
  }
  
  .navbar-expand-custom .navbar-nav .nav-item:last-child .nav-link {
    padding-right: 0rem;
  }

  
  

  /* Burger Css */
  
  .burger_container{
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    }
  .burger_container i{
    background-color: gray;
    width: 32px;
    height: 4px;
    margin: 3px;
    border-radius: 2px;
    transition: all ease 0.5s;
    }
      .burger_container .open:nth-child(1) {
        transform: rotate(45deg) translateY(5px) translateX(6px);
        opacity: 1;
      }
  
      .burger_container .open:nth-child(2) {
        opacity: 0;
      }
  
      .burger_container .open:nth-child(3) {
        transform: rotate(-45deg) translateY(-8px) translateX(9px);
        opacity: 1;
      }
  
  
      .burger_container .close:nth-child(1) {
        transform: rotate(0) translateY(0);
        opacity: 1;
      }
  
      .burger_container .close:nth-child(2) {
        opacity: 1;
      }
  
      .burger_container .close:nth-child(3) {
        transform: rotate(0) translateY(0);
        opacity: 1;
      }

@media screen and (max-width: 1230px) {
  .nav-ul img {
    margin-left: 0px;
  }
  .header .popup {
    width: 100%;
  }
  .navbar-brand {
    width: 110px;
    overflow: hidden;
    margin-right: 0;
  }
  .navbar-brand img {
    width: auto;
    height: 35px;
  }
  .nav-ul {
    display: block;
    padding: 25px 5px;
  }
  .navbar-expand-custom .navbar-nav .nav-link {
    text-align: center;
    margin-bottom: 10px;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    -webkit-transition: ease 0.5s all;
    -o-transition: ease 0.5s all;
    transition: ease 0.5s all;
  }
  .nav-ul img {
    margin-left: 0px;
  }
  nav.navbar.navbar-expand-custom {
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .navbar-expand-custom .navbar-nav .nav-item:last-child .nav-link {
    margin-bottom: 0rem;
  }
  .navbar-expand-custom .navbar-nav .nav-link:hover {
    background-color: aliceblue;
  }
  .navbar-toggler {
    position: absolute;
    right: 0px;
    top: 12px;
    z-index: 2;
  }
  .navbar-expand-custom > .container,
  .navbar-expand-custom > .container-fluid {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .navbar-brand {
    width: 130px;
    overflow: hidden;
    margin-right: 0;
}

.open-nav .navbar-brand{
  position: absolute;
  top: 13px;
}
  .navbar-brand img {
    width: auto;
    height: 41px;
  }
  .navbar.open-nav {
    position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        max-height: 100vh;
    }

.nav-ul {
  display: flex;
  padding: 0 0 25% 0;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  align-content: center;
  margin-top: 10%;
}
  .navbar-expand-custom .navbar-nav .nav-link {
    font-size: 16px;
    font-family: var(--regular-font);
    text-align: center;
    margin-bottom: 10px;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    -webkit-transition: ease 0.5s all;
    -o-transition: ease 0.5s all;
    transition: ease 0.5s all;
  }
  .navbar-expand-custom .navbar-nav .nav-item:last-child .nav-link {
    margin-bottom: 0rem;
  }
  .navbar-expand-custom .navbar-nav .nav-link:hover {
    background-color: #fff;
  }
  .navbar-toggler {
    border: 0px solid transparent;
  }
  .navbar-toggler {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  .navbar-expand-custom > .container,
  .navbar-expand-custom > .container-fluid {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (min-width: 1230px) {
  .navbar-expand-custom {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-custom .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-custom .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-custom .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-custom > .container {
    flex-wrap: nowrap;
  }
  .navbar-expand-custom .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-custom .navbar-toggler {
    display: none;
  }
}