.paterson-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paterson-page-heading-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paterson-page-heading-section .heading {
  font-weight: 400;
  font-size: 42px;
  line-height: 51px;
  text-align: center;
  color: #007cba;
  padding: 20px 0;
}

.paterson-page-heading-section img {
  width: 90%;
  height: auto;
  padding: 15px 0;
}
.paterson-page-sub {
  margin-bottom: 150px;
  max-width: 804px;
  margin-left: auto;
  margin-right: auto;
}
.paterson-page-sub p {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #333333;
}
.sub-images {
  padding: 10px 0;
}
.sub-images img {
  width: 100%;
  height: auto;
  padding: 20px 0;
}
.sub-images p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #454545;
}
.sub-heading1 {
  font-size: 34px;
  line-height: 42px;
  text-align: center;
  color: #333333;
  padding-top: 50px;
  margin-bottom: 2.5rem;
}
.sub-heading2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 26px;
  color: #007cba;
  padding: 35px 0;
}
.sub-heading3 {
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  color: #333333;
}
.sub-heading4 {
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  color: #333333;
  padding: 20px 0;
}

@media (max-width: 1230px) {
  .paterson-page-sub {
    width: 90%;
  }
  .paterson-page-heading-section img {
    width: 100%;
  }
}
